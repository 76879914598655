<template>
  <v-row
    ><v-col> <h2>Sivua ei löytynyt!</h2> </v-col></v-row
  >
</template>

<script>
export default {};
</script>

<style></style>
